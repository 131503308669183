import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
  
  <div class="pa-3">

    <uploadingDialog v-bind:uploadingDialog = "this.uploadingDialog"></uploadingDialog>
    <mySnackBar      v-bind:MySnackBar      = "this.MySnackBar"></mySnackBar>
    
    <v-container>
    <v-row class="pt-2 mb-2 d-flex justify-space-between align-baseline">
           
        <div> Enhanced Test Form</div>
         
        <div>   
         <v-btn  v-if = "this.$router.currentRoute.path != '/'"  @click="formReviewUpload"  class="mx-2" fab dark small color="red darken-3">
           <v-icon dark>mdi-file-pdf</v-icon>
         </v-btn>
      
         <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
          <v-icon dark>mdi-presentation-play</v-icon>
         </v-btn>
        </div>


   </v-row>
    </v-container>

    <v-row class="mt-0" justify="center">

    <v-expansion-panels class="expansion_panels">

      <!-- Instructions -->
      <v-expansion-panel class="expansion_panel mb-1"> 
        <v-expansion-panel-header>Enhanced Test Instructions</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-container class="header">
             Any fails in section 1, the tank life cannot be extended and a revalve/exchange will be required.
             If no fails are recorded in section 1 then section 2 can be completed to extend the life of the vessel,
             a standard 10 year test must also be completed.
            </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
   
       <!-- customerComponent -->
         <customer v-bind:customer = "customer" />
       <!-- customerComponent -->

      <!-- Vessel Details -->
        <v-expansion-panel class="expansion_pane mb-1"> 
            <v-expansion-panel-header>Vessel(s)</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container >
                <v-row dense>
                      <v-col v-for="(item, index) in limitedVessels" v-bind:key=index cols="12" sm="6">
                          <v-text-field  outlined dense v-model= "item.serialNumber"  :label   = "item.label"> </v-text-field>
                      </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
        </v-expansion-panel>
      <!-- end tank Details -->
     
      <!-- Section 1 - Check list -->
      <v-expansion-panel class="dl_expansion_pane mb-1"> 
        <v-expansion-panel-header  dense class="dl_expansion_panel_header">Section 1 - Check List</v-expansion-panel-header>
        <v-expansion-panel-content class="dl_expansion_panel_content">
            <v-container>
              <v-simple-table>
               
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Criteria
                      </th>
                      <th class="text-left">
                        Pass/Fail
                      </th>
                    </tr>
                  </thead>

                  <tbody>

                    <tr v-for="(item, index) in section_1" :key="index" >
                      <td>{{ item.criteria }}</td>
                      <td>
                      <toggle-button
                          :value = "item.criteria"
                          :labels = "{checked: 'Pass', unchecked: 'Fail'}"
                          :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                           color  = "#82C7EB"
                          :width = "60"
                          :height = "28"
                          v-model= "item.value"
                          @change= "(v) => item.value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
     
      <!-- Section 2 - Test Results and Replacement-->
      <v-expansion-panel class="dl_expansion_panel  mb-1"> 
        <v-expansion-panel-header  dense class="dl_expansion_panel_header">Section 2 - Test Results and Replacement/s</v-expansion-panel-header>
        <v-expansion-panel-content class="dl_expansion_panel_content">
            <v-container>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Criteria
                      </th>
                      <th class="text-left">
                        Completed
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="(item, index) in section_2" :key="index" >
                      <td>{{ item.criteria }}</td>
                      <td>
                      <toggle-button
                          :value = "item.criteria"
                          :labels = "{checked: 'Yes', unchecked: 'No'}"
                          :switch-color = "{checked: '#25EF02', unchecked: 'red'}" 
                          color  = "#82C7EB"
                          :width = "60"
                          :height = "26"
                          v-model= "item.value"
                          @change= "(v) => item.value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
             </v-container> 
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- last one for space-->
            <v-expansion-panel class="expansion_panel mb-2"> 
             </v-expansion-panel>
      <!-- last one for space-->

    </v-expansion-panels>

    </v-row>

  </div>

</template>

<script>

import {store} from '../../main.js'
import Localbase from 'localbase'

export default {
   name       : "enhancedTest",
   formName   : "enhancedTest",
   destroyed () {
          store.consoleLog( "enhancedTest destroyed" );

          let saveObject           =  this.generateSaveObject();
          const customer           =  this.customer['ACCOUNT'];
          const documentKey        =  this.$options.formName;
          this.saveDocument( customer, documentKey, saveObject);
        //  store.saveFormObject("enhancedTest", saveObject)
   },
   created   () { 

      store.consoleLog( "enhancedTest created" );

       this.customer  = JSON.parse( localStorage.getItem("equalResponseCustomer") );
       this.salesman  = JSON.parse( localStorage.getItem("equalResponseSalesman") );
       this.engineer  = JSON.parse( localStorage.getItem("equalResponseEngineer") );
       
       // also load the last enhancedTest form  if any   //
       const customer           = this.customer['ACCOUNT'];
       const documentKey        = this.$options.formName;
       this.retrieveDocument( customer, documentKey);

     
       /*
       var lastestObject = store.retrieveFormObject("enhancedTest");
       if ( lastestObject != null ) {
            this.vessels        = lastestObject.formReviewData.vessels;
            this.section_1      = lastestObject.formReviewData.section_1;
            this.section_2      = lastestObject.formReviewData.section_2;
       }
       */
  
   },
   methods   : {
                
              saveDocument( customer, documentKey, documentObject ){
              
                   let db     = new Localbase( this.$dbName );

                    db.collection( customer ).doc(documentKey).set( documentObject )
                    .then(response => {
                      console.log( response)
                    })
                    .catch(error => {
                      console.log( error)
                    })
              },

              retrieveDocument( customer, documentKey ){

                    let db = new Localbase(  this.$dbName );
                    db.collection(customer).doc(documentKey).get()
                    .then( lastestObject => {
                      if ( lastestObject != null ) {
                        this.vessels        = lastestObject.formReviewData.vessels;
                        this.section_1      = lastestObject.formReviewData.section_1;
                        this.section_2      = lastestObject.formReviewData.section_2;
                       }
                    })
                    .catch( error => {
                      console.log( error)
                    });
              },

                showSnackBar( message = "You need to review the form first..."){
                    this.MySnackBar.text          =  message;
                    this.MySnackBar.showImage     = "icon";
                    this.MySnackBar.show          = true;
                    setTimeout( () => { this.MySnackBar.show  = false }, 1000)
                },
                showUploadingDialog( message = "Uploading PDF...") {
                         this.uploadingDialog.message    = message;
                         this.uploadingDialog.showDialog = true;
                },
                closeUploadingDialog( message = "PDF Uploaded...") {
                          this.uploadingDialog.message    = message;
                          setTimeout( () => {
                                    this.uploadingDialog.message    = "";
                                    this.uploadingDialog.showDialog = false;
                                 }, 2000)
                },
                generateSaveObject() {

                                       let saveObject   =  { 
                                                      "formReviewName"   :  "enhancedTest",
                                                      "formReviewURL"    :  this.$restServer.enhancedTestURL,
                                                      "formReviewData"   :  {  
                                                                               "requestedResponse"    : "pdf",
                                                                               "customer"             : this.customer,
                                                                               "salesman"             : this.salesman,
                                                                               "engineer"             : this.engineer,
                                                                               "vessels"              : this.vessels,
                                                                               "section_1"            : this.section_1,
                                                                               "section_2"            : this.section_2,
                                                                            } 
                                                    };
                                     return saveObject;

                }, 
                generateLoggingObject() {
                     return  { 
                               "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                               "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                               "formName"    : this.formName 
                             };
                }, 
                formReviewUpload() {

                      const url                        =  this.$restServer.uploadPDFToDropBoxURL;
                      const formReviewReponse          = store.retrieveFormReviewResponse();
                      if ( formReviewReponse  ) {
                            let   self        = this;
                            formReviewReponse['loggingObject']     = this.generateLoggingObject();
                            this.showUploadingDialog();

                            this.$http.post( url, formReviewReponse ) 
                            .then(  (response) => {

                                store.resetFormReviewResponse();
                                self.closeUploadingDialog();
                            
                            })
                            .catch( (e) => { 
                                   self.closeUploadingDialog(e.message);
                                   localStorage.removeItem("equalResponsePdfReviewObject");
                                   console.log(e);
                            });
  
                        } else {
                             this.showSnackBar("You need to review the form first...");
                        }
                },
                formReview(e){
                    
                    try {
                            var saveObject  =  this.generateSaveObject();
                            store.saveFormObject("enhancedTest", saveObject)
                            this.$router.push( { name:"FormReview", "params" : saveObject } );
                    } catch (e) {
                         alert(e);
                    }
                },
   },
   computed  :{
            limitedVessels() {
               return this.vesselLimit ? this.vessels.slice(0,this.vesselLimit) : this.vessels
            },
            getSection1Labels(){
                const labels = {checked: 'Foo', unchecked: 'Bar'};
                return labels;
            }
   },
   data() {
    return {
        options          :  { penColor: "#c0f" },
         MySnackBar      :  { "show" : false, "text" : "Default Text", "showImage" : "icon"},
        uploadingDialog  :  { "showDialog" : false, "message": "" },
        formName    :  "enhancedTest",
        signature   :  {},
        customer    :  {},
        salesman    :  {},
        engineer    :  {} ,
        vesselLimit :  1,
        vessels     : [
                      { "label" : "Vessel 1 Serial Number", "serialNumber"  : "" },
                      { "label" : "Vessel 2 Serial Number", "serialNumber"  : "" },
                      { "label" : "Vessel 3 Serial Number", "serialNumber"  : "" }
                      ],
        section_1 : [
                    {"id":"1",  "criteria":"Calor Multi - 66/80",                           "value"  : false},
                    {"id":"2",  "criteria":"Combo Valve - Calor 84/10, 84/12 or 66/40",     "value"  : false},
                    {"id":"3",  "criteria":"Filler valve not fitted with manual shut off",  "value"  : false},
                    {"id":"4",  "criteria":"Flange or manway studs corroded",               "value"  : false},
                    {"id":"5",  "criteria":"Tank in liquid service",                        "value"  : false},
                    {"id":"6",  "criteria":"Bottom liquid offtake",                         "value"  : false},
                    {"id":"7",  "criteria":"No Data plate or illegible",                    "value"  : false},
                    {"id":"8",  "criteria":"Float gauge has non-stainless steel screws",    "value"  : false},
        ], 
        section_2 : [
                    {"id":"1", "criteria":"Excess flow on service valve",                                 "value"  : false},
                    {"id":"2", "criteria":"Vapour valve letby test",                                      "value"  : false},
                    {"id":"3", "criteria":"Vapour valve stem leak check",                                 "value"  : false},
                    {"id":"4", "criteria":"Regs fitted with UPSO/OPSO (if not upgrade)",                  "value"  : false},
                    {"id":"5", "criteria":"Fill valve seat leak check",                                   "value"  : false},
                    {"id":"6", "criteria":"Fill valve back check leak check",                             "value"  : false},
                    {"id":"7", "criteria":"Liquid withdrawel cap seal leak check",                        "value"  : false},
                    {"id":"8", "criteria":"Fill new filler valve washer",                                 "value"  : false},
                    {"id":"12", "criteria":"Replace contents gauge if unreadable",                         "value"  : false},
                    {"id":"13", "criteria":'1/4" pressure gauge/plug on vapour value replaced with 1/4" stainless plug ',       "value"  : false},
                    {"id":"14", "criteria":"Soundness Test",                                              "value"  : false},
        ] 
    
    };
   }
};
</script>

<style scoped>

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}


h1,h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.dl_expansion_panel_content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.header{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
}

</style>
             